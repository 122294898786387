
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: navFont;
  src: url(./assets/FUTURA.TTF);
}

.App {
  font-family: 'poppins';
  background-color: #1A181B;
}
.App > *{
  /* font-family: inherit; */
}

.heading{
  font-size: 80px;
  color: white;
  text-align: center;
  font-weight: 600;
  margin-top:-20px;
  /* margin-bottom: 20px; */
}
.subheading{
  font-size: 17px;
  color: #756F6A;
  text-align: center;
  margin-top: 20px;
}

@media only screen and (max-width:500px){
   
  .heading{
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: 600;
  }
  .subheading{
    /* margin-top: 40px; */
    margin-bottom: 10px;
}
  /* .about_content{grid-template-columns: 1fr;} */
         
  }

.scrollBtnDiv{
  position: fixed;
  bottom: 10%;
  right: 5%;
  background-color: #0067AC;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.5);         
}
.scrollBtn{
  font-size: 30px;
  cursor: pointer;
  color: white;
}