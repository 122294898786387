.About{min-height: 100vh;
/* background-color: black; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.about_content{
    color: white;
    display: grid;
    grid-template-columns: 6.5fr 3.5fr;
    padding-left: 60px;
    padding-right: 60px;
    text-align: justify;
    font-size: 16px;
    gap: 60px;
    align-items: center;
    margin-top: 40px;
}

.get_to_know_us{
    padding-top: 25px;
    /* padding-bottom: 40px; */
}

.about_img{height: 400px;}

/* @media only screen and (max-width:750px){
    .about_img_div{display: none;}
    .about_img{height: 50px;}
    
    .about_content{grid-template-columns: 1fr;}
           
} */

@media only screen and (max-width:800px){
   
.about_img{height: 300px;}

.about_content{grid-template-columns: 1fr; justify-items: center;}
       
}




@media only screen and (max-width:600px){
    .about_img{height: 250px;}
   
    
    .about_content{grid-template-rows: 1fr 0.5fr;
        font-size: 13px;
        margin-top: 100px;
    }

    .About{min-height: 100vh;
        /* background-color: black; */
        display: flex;
        flex-direction: column;
        /* gap:50px; */
        align-items: center;
        justify-content: center;
        }

     .subheading{
        /* margin-top: 40px; */
        margin-bottom: 10px;
    }  
     
     /* .heading{margin-top: -100px;} */
           
     .about_content{
       
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 40px;
        }
}