.clients{
    /* min-height: 100vh;  */
    background-color: white;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    padding: 100px 0;
}


.client_logos_div{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    row-gap: 64px;
    justify-content: center;
    margin-top: 40px;
}

.client_logos_div2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    row-gap: 64px;
    column-gap: 150px;
    justify-content: center;
    justify-items: center;
    margin: 64px auto 0;
}

.client_logos_img{
    object-fit: cover;
    /* height: 100%;
    width: 100%; */
    /* min-width:100px;  */
    /* max-width: 200px; */
    /* min-height: 80px;
    max-height: 80px; */
    width: 250px;
}

.client_logos{
    /* height: 100%;
    width: 100%; */
    justify-self: center;
    align-self: center;
}

.clients_heading{
    color: black;
}


@media only screen and (max-width:800px){
    
    .client_logos_div{
        /* margin-top: 20px; */
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
    }  
    .client_logos_div2{
        grid-template-columns: 1fr;
        margin-bottom: 20px;
    }

    .client_logos_img{
        width:225px; 
    }
    .clients_heading{
        margin-top: -10px;
    }

    /* .clients_pre_heading{margin-top: -70px;} */


    
}

@media only screen and (max-width:600px){
    
    .client_logos_div{
        margin-top: 60px;
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
    }  

    .client_logos_img{
        width:225px; 
    }
    .clients_heading{
        margin-top: -10px;
    }

    /* .clients_pre_heading{margin-top: -70px;} */

    
    
}

@media only screen and (max-width:400px){
    .clients{
        min-height: 120vh; 
        background-color: white;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        /* align-items: center; */
    
    }
    .client_logos_div{
        /* margin-top: 50px; */
        display: grid;
        grid-template-rows: auto;
        justify-content: center;
    }  

    .client_logos_img{
        width:225px; 
    }
    .clients_heading{
        margin-top: -20px;
    }

    /* .clients_pre_heading{margin-top: 100px;} */

    
    
}