.GlobalPresence{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mapDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    padding: 8px;
}

.map{
    /* width: 80vw; */
    object-fit: cover;
    max-height: 80vh;
    width: 100%;
}


@media only screen and (max-width:980px){
    
    .map{
       object-position: center;
       /* transform: scale(1.2) translateX(-150px); */
       /* height: 60vh; */
    }

    .mapDiv{
        /* width: 850px; */
        overflow-x: hidden;
        overflow-y: hidden;


    }
}

@media only screen and (max-width:600px){
    .GlobalPresence{
        min-height: 60vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .map{
        /* object-fit: cover; */
       /* object-position: 80%; */
       width: 100%;
       /* height: 30vh; */
       margin-top: 30px;
    }

    .mapDiv{
        /* width: 500px; */
        overflow-x: hidden;
        overflow-y: hidden;


    }
}
