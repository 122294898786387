.Product{
    min-height: 100vh;
    /* background-color: #1A181B; */
    padding: 60px 0;
}
.productGrid{
    display: grid;
    grid-template-columns: 400px 400px;
    /* grid-template-rows: repeat(4, 400px); */
    /* justify-items: center;
    align-items: center; */
    justify-content: center;
    gap: 20px;
    margin: 40px 100px;
}
.OddProductsGrid{
    display: grid;
    justify-content: center;
    gap: 20px;
    grid-template-rows: 400px 460px 300px 460px 400px 300px 300px 300px;
}
.EvenProductsGrid{
    justify-content: center;
    display: grid;
    gap: 20px;
    grid-template-rows: 350px 300px 300px 460px 400px 460px 350px 300px;
}
.products{
    height: 100%;
    width: 100%;
    position: relative;
}

.products> .blur-load{
    height: 100%;
    background-position: bottom;
    width: 100%;
}

.blur-load {
    background-repeat: no-repeat;
    background-size: cover;
    /* background-image: url(../../assets/lazy/1-customized-transformer.png) !important; */
  }
  
  .blur-load::before {
    content: "";
    position: absolute;
    inset: 0;
    opacity: 0;
    /* animation: pulse 2.5s infinite; */
    background-color: rgba(255, 255, 255, 0.295);
  }
  
  
  .blur-load.loaded::before {
    animation: none;
    content: none;
  }

  .products> .blur-load> img{
    object-fit: cover;
    height: 100%;
    width: 100%;
    object-position: bottom;
    cursor: pointer;
}

  .blur-load > img {
    opacity: 0;
    transition: opacity 250ms ease-in-out;    
  }
  
  .blur-load.loaded> img {
    opacity: 1;
  }

  .blur-load.loaded{
    background-image: none !important;
  }

  @keyframes pulse {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.1;
    }
    100% {
      opacity: 0;
    }
  }

.producthover{
    position: absolute;
    cursor: pointer;
    height: 97.5%;
    width: 95%;
    background-color: #000000d7;
    color: white;
    top: 0%;
    display: flex;
    flex-direction: column;
    /* gap: 15px; */
    align-items: center;
    justify-content: center;
    border: 1.5px solid #569dff;
    text-shadow: 0 0 8px #000000;
    text-align: center;
    padding: 2.5% 2.5% 0;
    font-size: 16px;
    font-weight: 400;
}
.bold{
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
}
.ACbold{
    font-weight: 700;
    font-size: 16px;
}
.ACsubtext{
    text-align: left;
}


.ModalContainer{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000a8;
    color: white;
    z-index: 5;
}

.ModalParent{
    border: 1.5px solid #ffffff;
    background-color: #000000cb;
}

.Modal{
    /* padding: 16px; */
    display: grid;
    overflow: scroll;
    max-height: 400px;
    max-width: 800px;
}

.Modal.four{
    grid-template-columns: 160px 180px 180px;
}

.Modal.eleven{
    grid-template-columns: 160px repeat(8,180px);
}

.Modal.fifteen{
    grid-template-columns: 160px 200px 200px;
}

.Modal.fourteen{
    grid-template-columns: 160px 300px;
}

.Modal::-webkit-scrollbar{display: none;}

.closeModal{
    position: absolute;
    top: 4px;
    right: 10px;
    border: none !important;
    z-index: 16;
    font-size: 20px;
    cursor: pointer;
    font-weight: 500 !important;
}

.Modal>div{
    border-style: solid;
    border-color: #ffffff49;
    border-width: 0.5px;
    padding: 8px 16px;
}

.modalHead{
    border-style: solid;
    border-color: #ffffff49;
    border-width: 0.5px;
    text-align: center;
    color: #ffffff;
    padding: 14px 32px;
    background-color: #000000;
    z-index: 14;
    position: sticky;
    top: 0;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
}

.model{
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 600;
    position: sticky;
    top: 0px;
    background-color: #000000;
    z-index: 12;
    text-align: center;
}

.specs{
    color: #ffffff ;
    font-weight: 500 ;
    position: sticky;
    left: 0;
    background-color: #000000;
    z-index: 11;
    font-size: 14px;
}

.specs.model{
    z-index: 13;
    text-align: left;
    font-weight: 600;
    font-size: inherit;
}

.data{
    font-weight: 300;
    font-size: 14px;
    text-align: center;
    color: #cacaca;
}


@media only screen and (max-width: 950px){
    .productGrid{
        grid-template-columns: 400px;
        /* grid-template-rows: repeat(6, 400px); */
    }
    .OddProductsGrid, .EvenProductsGrid{
        grid-template-rows: repeat(8, auto);
    }
    /* .product1{
        grid-row: 1/2;
    } */
    /* .product6{
        grid-row: 6/7;
        grid-column: 1/2;
    } */
    .productGrid>div>img{
        object-fit: cover;
        height: 100%;
        width: 100%;
        /* object-position: center; */
    }
    .Modal{
        max-width: 600px;
    }
}

@media only screen and (max-width: 600px){
    
    .productGrid, .EvenProductsGrid, .OddProductsGrid{
        gap: 24px;
    }
    
    .productGrid{
        display: grid;
        grid-template-columns: 300px ;
        /* grid-template-rows: repeat(4, 230px); */
        /* justify-items: center;
        align-items: center; */
        justify-content: center;
        /* gap: 20px; */
        margin: 40px 100px;
    }

    .products{
        display: flex;
        flex-direction: column;
    }
    .producthover{
        position: static;
        display: flex !important;
        background-color: #00000060;
        background-image: linear-gradient(#000000, #1A181B);
        border: none;

        font-size: 14px;
        justify-content: flex-start;
        /* border-bottom: 1px solid #ffffff; */
        /* box-shadow: 4px 4px 16px 0px rgba(255, 255, 255, 0.2);          */
    }
    .bold{
        font-size: 16px;
    }
    .ACbold{
        font-size: 14px;
    }

    .ModalParent{
        width: 80%;
    }
    .Modal{
        max-width: 100%;
        font-size: 14px;
    }
    .Modal.four{
        grid-template-columns: 150px 180px 180px;
    }
    
    .Modal.eleven{
        grid-template-columns: 150px repeat(8,180px);
    }
    
    .Modal.fifteen{
        grid-template-columns: 150px 200px 200px;
    }
    .Modal.fourteen{
        grid-template-columns: 150px auto;
    }
    .modalHead{
        font-size: 16px;
        padding: 12px 32px;
    }
    .specs{
        font-size: 12px;
    }
    .data{
        font-size: 12px;
    }
    .closeModal{
        font-size: 18px;
    }
}