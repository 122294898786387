.Footer{
    min-height: 50vh;
    height: 100%;
    color: white;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 50px; */
}

.quick_link:hover{
    cursor: pointer;
    text-decoration: underline;
}

.FooterContent{
    display: grid;
    grid-template-columns: 1fr 0.15fr 2fr 0.15fr 1.5fr;
    justify-items: center;
    align-items: center;
    padding: 0 30px;
    /* gap: 20px; */
    margin: 40px 0;
    font-family: navFont;
    font-size: 20px;
}

.footerCopyright{
    background-color: #0265B2;
    font-weight: 800;
    text-align: center;
    letter-spacing: 5px;
    width: 100%;
}

.middle_footer{
    display: grid;
    grid-template-columns: 2fr 8fr;
    gap:10px;
    /* align-items:  center; */
}

.footer_icons{
    justify-self: center;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer_text{
    align-self: center;
}

.quick_links{
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap:5px;
}

.footerCopyright>a{
    text-decoration: none;
    color: white;
}
.location_image_div_mobile{display: none;}

.vr{width: 2px;height: 100px; background-color: aliceblue;}

/* .vr.vr_right{
    justify-self:start;
} */

@media only screen and (max-width:800px){
    
    .quick_links{display: none;}
    .location_image_div{display: none;}
    .FooterContent{
     
     grid-template-columns: 1fr;
     /* justify-items: center;
     padding: 0 60px;
     gap: 20px; */
 }
 .location_image_div_mobile{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}
.Footer{
    padding-top: 100px;
}

.vr{display:none}
}

@media only screen and (max-width:600px){
    
   .quick_links{display: none;}
   .location_image_div{display: none;}
   .FooterContent{
    
    grid-template-columns: 1fr;
    /* justify-items: center;
    padding: 0 60px;
    gap: 20px; */
}
.location_image_div_mobile{
/* width: 100vw; */
display: flex;
justify-content: center;
align-items: center;
}

.vr{display:none}

.Footer{
    font-size: 14px;
}
.footerCopyright{
    font-size: 12px;
    font-weight: 600;
}
}


@media only screen and (max-width:450px){
    .FooterContent{
        grid-template-columns:  1fr;
        width: 300px;
        padding: 0;
        justify-content: center;
        gap: 5px;
    }

    .vr{display:none}

    .middle_footer{justify-content:center ;
    align-content: center;
    }



}